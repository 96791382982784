/* *************************** Tailwind Css ******************************** */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

    *,
    body {
        @apply font-sans box-border;
        font-family: "Poppins", system-ui !important;
    }

    html {
        --defaultDarkColor: #2653A2;
        --defaultLightColor: #bbcae3;
        --defaultDarkThemeColor: #101010;
        --defaultThemeRedColor: #8b0000;
        --defaultThemeLightRedColor: #f9f2f2;
    }

    h1 {
        font-size: 80px;
        font-weight: bold;
        font-style: italic;
        margin-bottom: 15px;
    }

    h2 {
        font-size: 40px;
        font-weight: bold;
        font-style: italic;
        margin-bottom: 15px;
    }

    h3 {
        font-size: 32px;
        font-weight: bold;
        font-style: italic;
        margin-bottom: 15px;
    }

    h4 {
        font-size: 20px;
        margin-bottom: 15px;
        font-weight: 600 !important;
    }

    p {
        font-size: 18px;
        margin-bottom: 15px;
    }

    .container,
    .container-fluid,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        margin-left: auto;
        margin-right: auto;
        padding-left: calc(var(--bs-gutter-x)*.5);
        padding-right: calc(var(--bs-gutter-x)*.5);
        width: 100%;
    }

    @media (min-width: 1200px) {

        .container,
        .container-lg,
        .container-md,
        .container-sm,
        .container-xl {
            max-width: 1140px !important;
        }
    }

    .poppins-thin {
        font-family: "Poppins", system-ui;
        font-weight: 100;
        font-style: normal;
    }

    .poppins-extralight {
        font-family: "Poppins", system-ui;
        font-weight: 200;
        font-style: normal;
    }

    .poppins-light {
        font-family: "Poppins", system-ui;
        font-weight: 300;
        font-style: normal;
    }

    .poppins-regular {
        font-family: "Poppins", system-ui;
        font-weight: 400;
        font-style: normal;
    }

    .poppins-medium {
        font-family: "Poppins", system-ui;
        font-weight: 500;
        font-style: normal;
    }

    .poppins-semibold {
        font-family: "Poppins", system-ui;
        font-weight: 600;
        font-style: normal;
    }

    .poppins-bold {
        font-family: "Poppins", system-ui;
        font-weight: 700;
        font-style: normal;
    }

    .poppins-extrabold {
        font-family: "Poppins", system-ui;
        font-weight: 800;
        font-style: normal;
    }

    .poppins-black {
        font-family: "Poppins", system-ui;
        font-weight: 900;
        font-style: normal;
    }

    .poppins-thin-italic {
        font-family: "Poppins", system-ui;
        font-weight: 100;
        font-style: italic;
    }

    .poppins-extralight-italic {
        font-family: "Poppins", system-ui;
        font-weight: 200;
        font-style: italic;
    }

    .poppins-light-italic {
        font-family: "Poppins", system-ui;
        font-weight: 300;
        font-style: italic;
    }

    .poppins-regular-italic {
        font-family: "Poppins", system-ui;
        font-weight: 400;
        font-style: italic;
    }

    .poppins-medium-italic {
        font-family: "Poppins", system-ui;
        font-weight: 500;
        font-style: italic;
    }

    .poppins-semibold-italic {
        font-family: "Poppins", system-ui;
        font-weight: 600;
        font-style: italic;
    }

    .poppins-bold-italic {
        font-family: "Poppins", system-ui;
        font-weight: 700;
        font-style: italic;
    }

    .poppins-extrabold-italic {
        font-family: "Poppins", system-ui;
        font-weight: 800;
        font-style: italic;
    }

    .poppins-black-italic {
        font-family: "Poppins", system-ui;
        font-weight: 900;
        font-style: italic;
    }

    /* in this example only 2 font family You can add more Font Family url or path */
    @font-face {
        font-family: 'Avenir';
        src: url('./Assets/Font/Avenir_TTF/Avenir-Book-01.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Avenir-Black';
        src: url('./Assets/Font/Avenir_TTF/Avenir-BlackOblique-04.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    ::-webkit-scrollbar {
        height: 9px;
        width: 8px
    }

    ::-webkit-scrollbar-track {
        background: #d9dcd7
    }

    ::-webkit-scrollbar-thumb {
        background: var(--defaultLightColor);
        border-radius: 3px
    }

    ::-webkit-scrollbar-thumb:hover {
        background: var(--defaultDarkColor)
    }
}

.d-flex {
    display: flex;
}

.container {
    padding-left: 15px;
    padding-right: 15px;
}

.align-center {
    align-items: center;
    justify-content: center;
    display: flex;
}

.space {
    padding-top: 5rem;
    padding-bottom: 5rem;
    background: #ffffff;
}

.theme-text-red {
    color: var(--defaultThemeRedColor);
}

.ant-input-outlined{
    /* border: none; */
}

.uploader-program .ant-upload{
 align-content: center;
}

.uploader-program .ant-upload-select{
    border: 1px dashed var(--defaultThemeRedColor);
}
/************************************************* Antd Overwritten Classes **************************************************/
.dark-btn {
    width: 200px;
    padding: 10px;
    background: #101010;
    color: white;
    border-radius: 4px;
}
.theme-btn {
    width: 200px;
    padding: 10px;
    background: var(--defaultThemeRedColor);
    color: white;
    border-radius: 4px;
}

.header-layout {
    width: 100%;
    margin: 0 auto;
}

/* Top Bar */
.top-bar {
    background-color: #000;
    /* Black background for the contact info */
    display: flex;
    height: 37px;
}

.logo-column {
    background-color: var(--defaultThemeRedColor);
    /* Red background for the logo */
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo {
    width: 100px;
}

.contact-column {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-right: 20px;
}

.contact-info {
    display: flex;
    gap: 30px;
}

.contact-item {
    color: #fff;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 10px;
}

/* Bottom Bar */
.bottom-bar {
    background-color: #fff;
    /* White background for the menu */
    padding: 15px 0;
    border-top: 1px solid #d3d3d3;
    display: flex;
}

.menu-column {
    display: flex;
    justify-content: flex-start;
}

.menu {
    display: flex;
    justify-content: space-around;
    background: none;
    border: none;
}

.ant-menu-light.ant-menu-horizontal>.ant-menu-item-selected::after {
    border-bottom-width: 0px !important;
}

.ant-menu-light.ant-menu-horizontal>.ant-menu-item-selected {
    color: var(--defaultThemeRedColor) !important;
}

:where(.css-dev-only-do-not-override-7ny38l).ant-card:not(.ant-card-bordered) {
    box-shadow: none !important;
}

.menu .ant-menu-title-content {
    font-weight: 600;
    text-transform: uppercase;
}

.button-column {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
    gap: 10px;
}

.theme-btn-red {
    background-color: var(--defaultThemeRedColor);
    color: #fff;
    border: none;
    width: 100%;
}

.theme-btn-dark {
    background-color: var(--defaultDarkThemeColor);
    color: #fff;
    border: none;
    width: 100%;
}

.box-shadow {
    box-shadow: 0px 10px 20px #8B00000D;
}

.radius-4 {
    border-radius: 4px;
}

.round-img {
    border-radius: 50px;
    height: 70px;
    width: 70px;
}

/* slider */
/* Full-width slider */
.slider {
    width: 100%;
    height: 100vh;
    /* Full view height for the slider */
}

.slider-item {
    height: 100vh;
    background-size: cover;
    background-position: center;
    position: relative;
    /* Needed for the overlay */
}

.slider-item::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.7) 75%,
            /* Black starts fading out at 85% */
            rgba(0, 0, 0, 0.5) 80%,
            /* Transition to a lighter black */
            rgba(255, 0, 0, 0.30) 100%
            /* Fully red at 100% */
        );
    z-index: 1;
    /* Overlay must be above the background image */
}

/* Center content within the slider */
.slider-content {
    text-align: center;
    color: #fff;
    /* Adjust color as per your design */
    z-index: 2;
    /* Content must be above the overlay */
    position: relative;
}

.slider-heading {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 20px;
}

.slider-paragraph {
    font-size: 24px;
    margin-bottom: 30px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    font-style: italic;
}
.search-box .ant-input{
    box-shadow: none;
    font-size: 12px!important;
}
.search-box .ant-btn{
    font-size: 12px!important;
}
/* Store link images */
.store-links {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.store-img {
    height: 50px;
    /* Adjust the size of the store images */
}

/* Background banner image */
.slider-item:nth-child(1) {
    background-image: url('../src//Assets/Media/banner1.png');
    /* Add correct path for your banner image */
}

.slider-item:nth-child(2) {
    background-image: url('../src//Assets/Media/banner1.png');
    /* Add more banners if needed */
}

/* Dot styles (if needed) */
.ant-carousel .slick-dots {
    bottom: 30px;
    /* Position dots lower */
}

.ant-carousel .slick-dots li button {
    background-color: #fff;
    /* Customize dot color */
}

/* service */
.service-box .service-item {
    height: 450px;
    align-items: center;
    align-content: center;
    opacity: 0.9;
    text-align: center;
}

.service-box .service-item p {
    padding-left: 5rem;
    padding-right: 5rem;
}

.rating {
    color: #ffc600;
    font-size: 24px;
}

.ant-form-item-control-input input {
    height: 40px;
    /* box-shadow: 0px 3px 5px #8B00000F;
    */
    border-radius: 10px;
    border: none;
}

.ant-input-group-addon {
    background: white !important;
    border: none !important;
}

.ant-picker {
    border: none;
    box-shadow: 0px 3px 5px #8B00000F;
}

.ant-input-affix-wrapper.css-dev-only-do-not-override-qnu6hi.ant-input-outlined {
    border: none;
    box-shadow: 0px 3px 5px #8B00000F;
}

.ant-select-single {
    height: 65px;
    /* border: 1px solid #8B000033;
    */
}

.ant-form-item-control-input-content textarea {
    height: 120px;
    border: 1px solid #8B000033;
}

.ant-btn-primary {
    background: var(--defaultThemeRedColor) !important;
    color: #ffffff !important;
}

.ant-btn-primary:hover {
    background: var(--defaultThemeRedColor) !important;
    color: #ffffff !important;
}

::placeholder {
    color: #000 !important;
    opacity: 0.5 !important;
}

.h-15 {
    height: 3.5rem !important;
}

/* //collapse */
.ant-collapse {
    border: 2px solid var(--defaultThemeRedColor);
    border-radius: 0;
}
.ant-picker-calendar .ant-picker-cell::before {
    height: 52px!important;
}

.ant-collapse>.ant-collapse-item {
    border-bottom: 2px solid var(--defaultThemeRedColor);
    padding: 10px;
    font-size: 20px;
    font-style: italic;
    font-weight: 500;
    background: #8B000005;
}

.ant-collapse-header-text {
    color: var(--defaultDarkThemeColor);
}

.comment-input input {
    border: none;
    background: var(--defaultThemeLightRedColor);
}

.comment-input .ant-input-group-addon {
    border: none;
    background: var(--defaultThemeLightRedColor) !important;
}

.store-links {
    display: flex;
    justify-content: center;
    gap: 50px;
}

.store-image {
    height: 80px;
    width: auto;
}

.bg-pink {
    background: #8B000005;
}

.footer-menu .ant-menu-title-content {
    font-size: 20px;
    font-weight: bold;
}

/* ///State list */
.stat-card {
    border-radius: 10px;
    background-color: #f9f9f9;
    padding: 20px;
}

.stat-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.stat-icon {
    margin-right: 10px;
    margin-top: -1rem;
}

.stat-text {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.stat-text p {
    font-size: 12px;
    font-weight: bold;
    color: #333;
}

.stat-value {
    font-size: 20px !important;
    font-weight: bolder !important;
    color: #333;
}

.stat-date {
    border-top: 1px solid #ddd;
    padding-top: 10px;
    margin-top: 10px;
}

.stat-date span {
    color: #333;
    font-weight: normal;
    font-size: 10px;
}

.stat-date h6 {
    margin: 0;
    font-size: 12px;
    color: #333;
    font-weight: bolder;
}

.stat-detail-button {
    text-align: right;
}

.stat-detail-button button {
    background-color: #8B0000;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
}

.star-ratings .widget-svg {
    height: 30px !important;
    width: 30px !important;
}

.uploader .ant-upload {
    display: block;
}

.post-section {
    padding-top: 20px;
    padding-bottom: 15rem;
    background: var(--defaultThemeLightRedColor);
}

.post-card {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.post-card .ant-btn-text {
    padding: 0;
}

.post-footer p {
    font-size: 12px;
    line-height: 1;
}

/* //classes section */
/* General Section Styling */
.classes-section {
    padding: 40px 20px;
    background-color: #f9f9f9;
}

/* Card Styling */
.class-card {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    padding: 10px;
}

.class-card p {
    font-size: 16px;
    line-height: 1.5;
}

.class-card:hover {
    transform: translateY(-5px);
}

.class-card img {
    height: 220px;
    object-fit: cover;
}

.card-content {
    padding: 0px;
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.card-title {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
}

.card-price {
    font-size: 20px;
    font-weight: bold;
    color: var(--defaultThemeRedColor);
    margin: 0;
}

.card-description {
    font-size: 12px;
    color: #666;
}

/* Responsive Grid */
.container {
    max-width: 1200px;
    margin: 0 auto;
}

/* //workout tracker */
/* General Section Styling */
.workout-section {
    background-color: #f9f9f9;
    padding: 40px 20px;
}

.section-title {
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 30px;
}

/* Rating Card */
.rating-card {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.star-score {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.score-number {
    font-size: 80px;
    font-weight: bold;
    color: var(--defaultThemeRedColor);
    position: absolute;
}

.star-icon {
    font-size: 120px;
    color: var(--defaultThemeLightRedColor);
}

.star-rating .fa-star {
    font-size: 30px;
    color: #f4c430;
}

.star-rating {
    gap: 15px;
    display: flex;
    justify-content: center;
}

.star-rating i:not(.active) {
    color: #ccc;
}

/* Punch History Card */
.punch-history-card {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.punch-history-title {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 10px;
}

.star-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 5px;
}

.star-grid i {
    font-size: 20px;
    color: #f4c430;
}

.star-grid i:not(.active) {
    color: #ccc;
}

/* Timer and Controls */
.timer-controls {
    text-align: center;
    margin-top: 20px;
}

.timer {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px;
}

.control-buttons button {
    margin: 0 10px;
    font-size: 20px;
}

.stop-button {
    background-color: #d32f2f !important;
    color: #fff !important;
}

.custom-tabs .ant-tabs-nav {
    justify-content: center;
    /* Center the tabs */
    margin: 0;
    border-bottom: none;
    /* Remove border at the bottom of the tabs */
}

.custom-tabs .ant-tabs-tab {
    font-size: 16px;
    padding: 15px 80px;
    border-radius: 4px;
    /* Remove rounded edges */
    margin: 0 !important;
    /* Remove gaps between tabs */
    border: none;
    /* Remove border around tabs */
}

.custom-tabs .ant-tabs-tab-active {
    background-color: #8b0000;
    /* Active tab background color */
    color: #fff;
    /* Active tab text color */
}

.custom-tabs .ant-tabs-tab:not(.ant-tabs-tab-active) {
    background-color: #8b00000d;
    /* Inactive tab background color */
    color: #8b0000;
    /* Inactive tab text color */
}

.custom-tabs .ant-tabs-tab:hover {
    background-color: rgba(139, 0, 0, 0.2);
    /* Slightly darker on hover */
    color: #8b0000;
}

.custom-tabs .ant-tabs-ink-bar {
    display: none;
    /* Hide the default ink bar */
}

.d-none {
    display: none;
}

/* //// profile */
.profile-header {
    text-align: center;
    margin: 20px 0;
}

.profile-info {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profile-image {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    margin-bottom: 10px;
}

.profile-name {
    font-size: 1.3rem;
    font-weight: bold;
}

.profile-email {
    margin-bottom: 10px;
    font-size: 1rem;
    font-weight: 500;
}

.logout-button {
    background-color: #c1272d;
    border: none;
    font-weight: bold;
}

.settings-section {
    margin: 20px 0;
    padding: 0 20px;
}

.settings-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 20px;
}

.settings-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 55px;
    background: #f7eded;
    font-weight: bold;
}

.settings-card .ant-card-body {
    width: 100%;
}

.settings-icon {
    font-size: 1.5rem;
    color: #c1272d;
}

.ant-menu-overflow {
    width: 100%;
}

.anticon svg {
    /* color: var(--defaultThemeRedColor);
    */
    font-weight: bold;
}

.search-box {
    background: #faeded;
}

.ant-input-prefix {
    background: var(--defaultThemeRedColor);
    padding: 1px 6px;
    border-radius: 4px;
}

.ant-input-prefix .anticon svg {
    color: white;
}

.appointment-card .ant-card-body {
    padding-bottom: 0;
    box-shadow: 0px 0px 20px #8B00000D;
    ;
}

/* /// */
/* Subtitle styles for "Payment Options" and "Booking Details" */
.section-subtitle {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

/* Card styles */
.card {
    padding: 20px;
    background: #fdfdfd;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Options grid */
.option {
    padding: 15px;
    text-align: center;
    background: var(--defaultThemeLightRedColor);
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s;
}

.option span {
    display: block;
    font-size: 16px;
    font-weight: bold;
    color: var(--defaultThemeRedColor);
}

.option:hover {
    border-color: var(--defaultThemeRedColor);
}

.option.active {
    background: var(--defaultThemeRedColor) !important;
    color: white;
}

.ant-radio-wrapper span.ant-radio+* {
    font-weight: bold;
}

.option.active span {
    color: white !important;
}

.class-type {
    margin-top: 20px;
}

/* Details and pricing */
.details {
    line-height: 2.8 !important;
}

.details p,
.pricing p {
    margin: 5px 0;
    font-weight: bold;
}

.details span,
.pricing span {
    float: right;
    font-weight: normal;
}

.ant-radio-wrapper .ant-radio-checked::after {
    border: 1px solid var(--defaultThemeRedColor) !important;
}

.container {
    max-width: 1200px;
    margin: auto;
}

.section-subtitle {
    font-size: 20px;
    font-weight: bold;
}

.card {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.option {
    text-align: center;
    padding: 10px;
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
}

.option.active {
    background: #d9534f;
    color: #fff;
}

.saved-cards .saved-card {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

.card-icon {
    width: 40px;
    margin-right: 10px;
}

.pricing p {
    margin: 5px 0;
}

.total {
    font-weight: bold;
    font-size: 18px;
}

.total-price {
    color: green;
}

.input-field {
    width: 100%;
    border-radius: 5px;
}

.save-card {
    margin-top: 15px;
}

.stats-cards .ant-card-body {
    padding: 24px;
    padding-top: 15px !important;
    padding-bottom: 10px !important;
}

/* /// calender */
.title-bar {
    text-align: center;
    background: var(--defaultThemeLightRedColor);
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    margin-top: 40px;
}

.calendar-card {
    padding: 20px;
}

.slots-card {
    padding: 20px;
}

.session-title {
    margin-bottom: 15px;
    font-weight: bold;
    color: black;
    font-size: 16px;
    margin-top: 15px;
}

.time-slot {
    box-shadow: 0px 5px 15px #1616160D;
    border-radius: 10px;
    font-size: 14px;
    height: 40px;
    margin-bottom: 10px;
    border: none;
}

.time-slot.active {
    background-color: var(--defaultThemeRedColor);
    color: white;
    border-color: var(--defaultThemeRedColor);
}

.calendar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffe9e9;
    padding: 15px 0;
}

.ant-picker-cell-selected div {
    border-radius: 4px !important;
    background: var(--defaultThemeRedColor);
    padding: 7px 10px;
}

.ant-picker-calendar .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: none !important;
}

.ant-picker-calendar .ant-picker-cell-in-view.ant-picker-cell-selected:not(.ant-picker-cell-disabled) .ant-picker-cell-inner {
    background: transparent;
}

.calendar-title {
    font-size: 18px;
    font-weight: bold;
    color: var(--defaultThemeRedColor);
}

.calendar-arrow {
    background-color: transparent;
    border: none;
    color: var(--defaultThemeRedColor);
}

.ant-calendar-date {
    border-radius: 50%;
    cursor: pointer;
}

.ant-calendar-date:hover {
    background-color: var(--defaultThemeRedColor);
    color: white;
}

.ant-picker-calendar-date-content {
    display: none;
}

.ant-picker-content {
    line-height: 3;
}

.ant-picker-content thead {
    background: #F8F8F8;
    height: 50px;
}

.ant-picker-content thead th {
    font-weight: bold !important;
}

.ant-picker-body {
    padding-top: 0 !important;
}

.bookmark-btn {
    padding: 0;
    margin: 0;
    color: "#555";
    background: #8B000026;
    color: var(--defaultThemeRedColor);
    border: 1px solid var(--defaultThemeRedColor);
    padding-left: 15px;
    padding-right: 15px;
}

.nobookmark-btn {
    padding: 0;
    margin: 0;
    color: "#555";
    background: #ffffff!important;
    color: var(--defaultThemeRedColor)!important;
    border: 1px solid var(--defaultThemeRedColor);
    padding-left: 15px;
    padding-right: 15px;
}

.program-expend {
    border: none;
}

.program-expend .ant-collapse-item {
    border: none;
    margin-bottom: 10px;
    box-shadow: 0px 5px 10px #8B00001A;
    background: white;
}

.program-expend .ant-collapse-item .ant-collapse-expand-icon {
    display: none !important;
}

.program-expend .anticon-plus,
.anticon-minus {
    font-size: 30px;
}

.payment-card .ant-radio-group {
    width: 100%;
}

/* //filter */
.filter-modal {
    max-width: 430px;
    justify-content: center;
    align-items: center;
}

.filter-box {
    line-height: 3;
    font-weight: bold;
}

.ant-checkbox-wrapper {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--defaultThemeRedColor);
    /* Custom background color when checked */
    border-color: var(--defaultThemeRedColor)
        /* Border color for checked state */
}

.ant-checkbox-inner {
    width: 16px;
    /* Customize size if needed */
    height: 16px;
    border-radius: 4px;
    /* Optional: Makes the checkbox corners rounded */
    border: 1px solid #d9d9d9;
    /* Default border color */
    transition: all 0.3s;
    /* Smooth transition for hover or state change */
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
    border-color: #40a9ff;
    /* Highlight border color on hover */
}

.ant-checkbox-input:checked+.ant-checkbox-inner {
    background-color: var(--defaultThemeRedColor);
    border-color: var(--defaultThemeRedColor)
}

/* /// trainer profile*/
/* Container for the entire card and image */
.custom-card {
    position: relative;
    margin-top: 50px;
    /* Create space for the overlapping image */
}

/* Image container */
.image-container {
    position: absolute;
    top: -50px;
    /* Position image to overlap the card */
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    /* Ensure the image is above the card */
}

/* Profile image styling */
.profile-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    /* Make the image circular */
    border: 4px solid #fff;
    /* Add a white border for better contrast */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Optional: Add shadow for a modern look */
}

/* Card styling */
.class-card {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Title and price styling */
.card-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.add-training-video .ant-upload-select {
    width: 100%;
    height: 200px;
    border: 2px dashed var(--defaultThemeRedColor);
    /* padding: 15px; */
    border-radius: 8px;
    margin-bottom: 15px;
    background: #f7eded;
    color: var(--defaultThemeRedColor);
    font-size: 30px;
    align-content: center;
}

.add-training-video .upload-info {
    width: 100%;
    border: none;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 15px;
    background: #f7eded;
}

.otp_input_container {
    color: var(--defaultThemeRedColor) !important;
    font-weight: 600;
}

.slot-select{
    background: var(--defaultThemeRedColor);
    width: 100%;
    padding: 15px;
    color: white;
    font-weight: bold;
    border-radius: 8px;
}
.slot-select option{
    background: var(--defaultThemeLightRedColor);
    color: #000;
}
.react-tel-input .flag-dropdown {
    background-color: white!important;
    border: none!important;
}
.react-tel-input .selected-flag {
   border: 1px solid rgb(217, 217, 217);
  }

.ant-form-item-control-input .anticon svg{
    color: var(--defaultThemeRedColor);
}

.ant-input{
    box-shadow: 0px 3px 5px #8B00000F;
}

.payment-card iframe{
    height: 60px!important;
    border: none!important;
}

.notification-modal {
    max-height: 500px;
    overflow-y: auto;
    padding: 16px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .notification-list {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  
  .notification-item {
    display: flex;
    flex-direction: column;
    background: var(--defaultThemeLightRedColor);
    margin-bottom: 12px;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  }
  
  .notification-header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  
  .notification-user-image {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-right: 16px;
    object-fit: cover;
  }
  
  .notification-details {
    flex: 1;
  }
  
  .notification-user-name {
    font-size: 16px;
    font-weight: 600;
    color: #333;
    margin-bottom: 4px;
  }
  
  .notification-message {
    font-size: 14px;
    color: #555;
    margin-bottom: 8px;
  }
  
  .notification-time {
    font-size: 12px;
    color: #999;
  }
  
  .notification-content {
    padding-top: 8px;
    font-size: 14px;
    color: #444;
  }
  
  .load-more-container {
    text-align: center;
    margin-top: 16px;
  }
  
  .load-more-container button {
    border-radius: 20px;
    padding: 8px 16px;
    font-size: 14px;
  }
  
  .loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100%;
  }
  
  /* Mobile-friendly design adjustments */
  @media (max-width: 768px) {
    .notification-header {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .notification-user-image {
      margin-right: 0;
      margin-bottom: 8px;
    }
  
    .notification-details {
      margin-left: 0;
    }
  
    .notification-user-name {
      font-size: 14px;
    }
  
    .notification-message {
      font-size: 12px;
    }
  
    .notification-time {
      font-size: 10px;
    }
  }
  
@media only screen and (max-width: 728px) {
    .classes-section {
        padding: 0;
    }

    .custom-tabs .ant-tabs-tab {
        padding: 10px 35px;
    }

    .ant-layout-header {
        height: auto;
    }

    .contact-column {
        display: block;
        padding-right: 15px;
        padding-left: 15px;
    }

    .contact-info {
        line-height: 3;
    }

    .footer-p {
        font-size: 14px;
        padding-left: 15px;
    }
}